import { get_config } from '@/models/changeType';
import '@mdi/font/css/materialdesignicons.css'
import axios from 'axios';
import Vue from 'vue';
import Vuetify from 'vuetify';
Vue.use(Vuetify);
get_config()
let config = {} as any
config = localStorage.getItem('config')
if (!config) {
  axios.get('/api/Store/Config/get').then(Response => {
    localStorage.setItem('config', JSON.stringify(Response.data.Result))
    axios.post('/api/Social/Galleries/getGalleryItems',{
      gallery_id: Response.data.Result.logo_galler_id
    }).then(Response => {
      localStorage.setItem('logo', Response.data.Result[2].thumbnail)
      location.reload()
    })
  })
}
const _config = JSON.parse(config)
const themes = JSON.parse(_config.theme)
if (!themes) {
  localStorage.removeItem('config')
  axios.get('/api/Store/Config/get').then(Response => {
    localStorage.setItem('config', JSON.stringify(Response.data.Result))
    location.reload()
  })
}
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('dynamic-style');
    link.id = 'dynamic-style';
    link.rel = 'stylesheet';
    link.href = `/template/mobile/style/${_config.client_id}.css`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
  // <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('apple-icon-57x57')
    link.id = 'apple-icon-57x57'
    link.rel = 'apple-touch-icon'
    link.href = `/template/mobile/images/icons/${_config.client_id}/apple-icon-57x57.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
  // <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('apple-icon-60x60');
    link.id = 'apple-icon-60x60';
    link.rel = 'apple-touch-icon';
    link.href = `/template/mobile/images/icons/${_config.client_id}/apple-icon-60x60.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
  // <link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('apple-icon-72x72');
    link.id = 'apple-icon-72x72';
    link.rel = 'apple-touch-icon';
    link.href = `/template/mobile/images/icons/${_config.client_id}/apple-icon-72x72.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
  // <link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('apple-icon-76x76');
    link.id = 'apple-icon-76x76';
    link.rel = 'apple-touch-icon';
    link.href =  `/template/mobile/images/icons/${_config.client_id}/apple-icon-76x76.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
  // <link rel="apple-touch-icon" sizes="114x114" href="/apple-icon-114x114.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('apple-icon-114x114');
    link.id = 'apple-icon-114x114';
    link.rel = 'apple-touch-icon';
    link.href =  `/template/mobile/images/icons/${_config.client_id}/apple-icon-114x114.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
  // <link rel="apple-touch-icon" sizes="120x120" href="/apple-icon-120x120.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('apple-icon-120x120');
    link.id = 'apple-icon-120x120';
    link.rel = 'apple-touch-icon';
    link.href = `/template/mobile/images/icons/${_config.client_id}/apple-icon-120x120.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
  // <link rel="apple-touch-icon" sizes="144x144" href="/apple-icon-144x144.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('apple-icon-144x144');
    link.id = 'apple-icon-144x144';
    link.rel = 'apple-touch-icon';
    link.href = `/template/mobile/images/icons/${_config.client_id}/apple-icon-144x144.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
  // <link rel="apple-touch-icon" sizes="152x152" href="/apple-icon-152x152.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('apple-icon-152x152');
    link.id = 'apple-icon-152x152';
    link.rel = 'apple-touch-icon';
    link.href = `/template/mobile/images/icons/${_config.client_id}/apple-icon-152x152.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
  // <link rel="apple-touch-icon" sizes="180x180" href="/apple-icon-180x180.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('apple-icon-180x180');
    link.id = 'apple-icon-180x180';
    link.rel = 'apple-touch-icon';
    link.href = `/template/mobile/images/icons/${_config.client_id}/apple-icon-180x180.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
  // <link rel="icon" type="image/png" sizes="192x192"  href="/android-icon-192x192.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('android-icon-192x192');
    link.id = 'android-icon-192x192';
    link.rel = 'icon';
    link.href =`/template/mobile/images/icons/${_config.client_id}/android-icon-192x192.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
  // <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('favicon-32x32');
    link.id = 'favicon-32x32';
    link.rel = 'icon';
    link.type = "image/png"
    link.href = `/template/mobile/images/icons/${_config.client_id}/favicon-32x32.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }


  // <link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('favicon-96x96');
    link.id = 'favicon-96x96';
    link.rel = 'icon';
    link.type = "image/png"
    link.href = `/template/mobile/images/icons/${_config.client_id}/favicon-96x96.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }


  // <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('favicon-16x16');
    link.id = 'favicon-16x16';
    link.rel = 'icon';
    link.type = "image/png"
    link.href = `/template/mobile/images/icons/${_config.client_id}/favicon-16x16.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }


  // <meta name="msapplication-TileColor" content="#ffffff">
  if (_config) {
    let link = document.createElement('meta'),
    oldLink = document.getElementById('msapplication-TileColor');
    link.id = 'msapplication-TileColor';
    link.name = 'msapplication-TileColor';
    link.content = "#ffffff"
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }

  // <meta name="msapplication-TileImage" content="/ms-icon-144x144.png">
  if (_config) {
    let link = document.createElement('meta'),
    oldLink = document.getElementById('dynamic-favicon');
    link.id = 'dynamic-style';
    link.name = 'msapplication-TileImage'
    link.content = `/template/mobile/images/icon/${_config.client_id}/ms-icon-144x144.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }

  // <meta name="theme-color" content="#ffffff">
  if (_config) {
    let link = document.createElement('meta'),
    oldLink = document.getElementById('dynamic-favicon');
    link.id = 'dynamic-style';
    link.name = 'theme-color'
    link.content = themes.light.primary
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
export default new Vuetify({
    rtl: true,
    icons: {
      iconfont: 'mdiSvg',
    },
    theme: {
        themes: {
        light: 
        {
        accent: "#D6CCCD",
        accentText: "#ffffff",
        appBar: "#FFFFFF",
        background: "#F7F7F7",
        basketBtnFilltext: "#ec202f",
        blocksFont: "#414141",
        check: "#1565C0",
        darkColor: "#212121",
        datesColor: "#9F9E9C",
        dividers: "#FBF6E8",
        error: "#B71C1C",
        headerColor: "#ffffff",
        info: "#1E88E5",
        light: "#FAFAFA",
        lightGray: "#6C6E6E",
        loadingColor: "#3D011D",
        muted: "#7D7B7C",
        numbersBg: "#F5F5F5",
        offLabel: "#6e304b",
        primary: "#6e304b",
        primaryText: "#3D011D",
        productBtn: "#f8f8f8",
        rateIcons: "#d8d8d8",
        searchBox: "#64646410",
        secondary: "#F9D7AB",
        secondaryText: "#e2ae6c",
        subheaderFont: "#414141",
        subtitleFont: "#434242",
        success: "#0e7b46",
        titleFont: "#322F2F",
        warning: "#FFA000",
      }
      }
    }
});