import axios from "axios"
import Store from '@/store/index'
import router from "@/router"
type productsBasket = {
    count: string;
    price: string | number;
    Is_Bulk: string;
    Org_ID: string;
    id: string;
    image: string;
    max_request: string;
    onhand: string;
    title: string;
    unit_title: string;
}

const token = localStorage.getItem('access_token')

export function addTObasket (orders: any) {
  const Token = localStorage.getItem('access_token')
  if (!Token) {
    Store.dispatch('alertError', {show: true, type: 'no_login'})
    setTimeout(() => {
      Store.dispatch('alertError', {show: false, type: 'no_login'})
    }, 2500);
    router.push('/login')
    return
  }
  Store.dispatch('addBasket_status', false)
  axios.post('/api/Store/Basket/addToBasket',orders.product,{
    headers: {
    'Authorization': `Bearer ${token}`
    }
}).then(Response => {
  if (Response.data.error) {
    if (Response.data.error == 'basket_change_error') {
      Store.dispatch('alertError', {show: true, type: 'basket_update'})
    setTimeout(() => {
      Store.dispatch('alertError', {show: false, type: 'basket_update'})
    }, 2500);
    Store.dispatch('addBasket_status', true)
    Store.dispatch('addBasket_error', true)
    setTimeout(() => {
      Store.dispatch('addBasket_error', false)
    }, 1000);
    return
    } else {
      Store.dispatch('alertError', {show: true, type: 'error_add_basket'})
    setTimeout(() => {
      Store.dispatch('alertError', {show: false, type: 'error_add_basket'})
    }, 2500);
    Store.dispatch('addBasket_status', true)
    Store.dispatch('addBasket_error', true)
    setTimeout(() => {
      Store.dispatch('addBasket_error', false)
    }, 1000);
    return
    }
  }
  Store.dispatch('addBasket', {price: orders.price, type: orders.type, product: orders.product})
  Store.dispatch('addBasket_status', true)
  Store.dispatch('addcountBasket', orders.product)
  Store.dispatch('addBasket_error', false)
}).catch (() => {
  Store.dispatch('alertError', {show: true, type: 'network'})
})
}
export function removeTObasket (orders: {}) {
  const Token = localStorage.getItem('access_token')
  if (!Token) {
    Store.dispatch('alertError', {show: true, type: 'no_login'})
    setTimeout(() => {
      Store.dispatch('alertError', {show: false, type: 'no_login'})
    }, 2500);
    router.push('/login')
    return
  }
    axios.post('/api/Store/Basket/removeFromBasket',orders,{
      headers: {
      'Authorization': `Bearer ${token}`
      }
  }).then(Response => {
    if (Response.data.Result.error) {
      Store.dispatch('alertError', {show: true, type: 'network'})
      return
    }
    Store.dispatch('removeBasket', orders)
    Store.dispatch('removecountBasket', orders)
  }).catch (() => {
    // Store.dispatch('alertError', {show: true, type: 'network'})
})
}

export function viewbasket () {
    axios.get('/api/Store/Basket/show',{
      headers: {
      'Authorization': `Bearer ${token}`
      }
  }).then(Response => {
    console.log(Response.data)
    if (Response.data.Result.products.length == 0) {
      router.push('/basket')
      return
    }
      Store.dispatch('payment', Response.data.Result.basket)
      console.log(Response.data.Result.basket.purses)
      Store.dispatch('purse_basket', Response.data.Result.basket.purses)
  }).catch ((e) => {
    const error = console.error(e);
  })
}
export function viewbasket_products () {
  Store.dispatch('load_page_basket', true)
  axios.get('/api/Store/Basket/showProducts',{
    headers: {
    'Authorization': `Bearer ${token}`,
    }
}).then(Response => {
  Store.dispatch('load_page_basket', false)
  if (Response.data.Result.products) {
    Store.dispatch('basket', Response.data.Result)
    const time = new Date();
    const now = time.getTime();
    localStorage.setItem('time_basket', JSON.stringify(now))
  }
}).catch ((e) => {
  const error = console.error(e);
})
}

export function view_times_deliveries () {
  const d = new Date();
  const h = d.getHours()
  const m = d.getMinutes()
  const s = d.getSeconds()
  const ms = d.getMilliseconds()
   const view_times = { time: h + ":" + m + ":" + s + ":" + ms}
   localStorage.setItem('req_view_times', JSON.stringify(view_times))
  Store.dispatch('load_page', true)
  axios.get('/api/Store/Basket/show',{
    headers: {
    'Authorization': `Bearer ${token}`
    }
}).then(Response => {
  const d_r = new Date();
  const h_r = d_r.getHours()
  const m_r = d_r.getMinutes()
  const s_r = d_r.getSeconds()
  const ms_r = d_r.getMilliseconds()
   const res_view_times = { time: h_r + ":" + m_r + ":" + s_r + ":" + ms_r}
   localStorage.setItem('res_view_times', JSON.stringify(res_view_times))
  if (Response.data.error == 'basket_change_error') {
    router.push('/basket')
  }
  Store.dispatch('load_page', false)
  if (Response.data.Result.products.length == 0) {
    router.push('/basket')
  }
  Store.dispatch('deliveries', Response.data.Result.basket.cargoes[0])
}).catch ((e) => {
  // const error = console.error(e);
})
}

export function basketResiver() {
  Store.dispatch('load_page', true)
    axios.get('/api/Store/Basket/getAddresses',{
      headers: {
      'Authorization': `Bearer${token}`
      }
     }).then(Response => {
      Store.dispatch('load_page', false)
      if (Response.data.Result.error) {
        Store.dispatch('alertError', {show: true, type: 'network'})
        return
      }
       Store.dispatch('setAddressUser', Response.data.Result)
     }).catch (() => {
      // Store.dispatch('alertError', {show: true, type: 'network'})
  })
}

export function deletedAddressReciver (addressID: '') {
  Store.dispatch('load_page', true)
  axios.post('/api/Store/Basket/deleteAddress', 
  {
      address_id: addressID
  },{
      headers: {
      'Authorization': `Bearer ${token}`
      }
  }).then(Response => {
    Store.dispatch('load_page', false)
    if (Response.data.Result.error) {
      Store.dispatch('alertError', {show: true, type: 'network'})
      return
    }
      if (Response.data.Result == true) {
        Store.dispatch('removeAddressUser', addressID)
      }
  }).catch (() => {
    // Store.dispatch('alertError', {show: true, type: 'network'})
})
}

export function submitReciver (addressID: '') {
  Store.dispatch('load_page', true)
  axios.get(`/api/Store/Basket/chooseAddress/${addressID}`,{
      headers: {
      'Authorization': `Bearer ${token}`
      }
  }).then(Response => {
    Store.dispatch('load_page', false)
    if (Response.data.Result.error) {
      Store.dispatch('alertError', {show: true, type: 'network'})
      return
    }
      router.push('/send_time')
  }).catch (() => {
    // Store.dispatch('alertError', {show: true, type: 'network'})
})
}

export function setReceiver(address: {}) {
  Store.dispatch('load_page', true)
  axios.post('/api/Store/Basket/setAddress', address,{
    headers: {
    'Authorization': `Bearer ${token}`
    }
}).then(Response => {
  Store.dispatch('load_page', false)
  if (Response.data.Result.error) {
    Store.dispatch('alertError', {show: true, type: 'network'})
    return
   }
    if (Response.data.Result.success == 1) {
      submitReciver(Response.data.Result.address[0].id)
    }
}).catch (() => {
  // Store.dispatch('alertError', {show: true, type: 'network'})
 })
}

export function viewProducts (productID: '') {
  Store.dispatch('load_page', true)
  axios.post('/api/Store/Products/view',{
    product_id: productID
  }).then(Response => {
    Store.dispatch('load_page', false)
    if (Response.data.Result.error) {
      Store.dispatch('alertError', {show: true, type: 'network'})
      return
    }
    Store.dispatch('productDetail', Response.data.Result)
  }).catch (() => {
    // Store.dispatch('alertError', {show: true, type: 'network'})
})
}

export function setTimeSend (timeSend: any) {
  Store.dispatch('load_page', true)
  axios.post('/api/Store/Basket/chooseDelivery',timeSend,{
      headers: {
      'Authorization': `Bearer ${token}`
      }
  }).then(Response => {
    Store.dispatch('load_page', false)
    if (Response.data.Result.error) {
      Store.dispatch('alertError', {show: true, type: 'network'})
      return
    }
    if (Response.data.Result == true) {
      router.push('/payment')
    }
  }).catch (() => {
    // Store.dispatch('alertError', {show: true, type: 'network'})
})
}

export function similarProduct (productID: string) {
  axios.post('/api/Store/Products/similar',{
    product_id: productID
  }).then(Response => {
  if (Response.data.Result.error) {
    Store.dispatch('alertError', {show: true, type: 'network'})
    return
  }
  Store.dispatch('similarProducts', Response.data.Result)
}).catch (() => {
  // Store.dispatch('alertError', {show: true, type: 'network'})
})
}

export function coupon (coupone: string) {
  Store.dispatch('load_page', true)
  axios.post('/api/Store/Basket/getCoupon',{
    code: coupone
  },{
    headers: {
    'Authorization': `Bearer ${token}`
    }
}).then(Response => {
  Store.dispatch('load_page', false)
  if (Response.data.error == 'invalid_coupon') {
    Store.dispatch('invalid_coupon', Response.data.error_description)
    return
  }
  Store.dispatch('cal_coupon', Response.data.Result)
}).catch (() => {
  // Store.dispatch('alertError', {show: true, type: 'network'})
})
}

export function submit_pay(payment: {}) {
  Store.dispatch('load_page', true)
  axios.post('/api/Store/Basket/submit', payment,{
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }).then(Response => {
    Store.dispatch('load_page', false)
    if (Response.data.error == 'Basket_Error') {
      Store.dispatch('alertError', {show: true, type: 'error_submit_basket'})
      setTimeout(() => {
        Store.dispatch('alertError', {show: false, type: 'error_submit_basket'})
        router.push('/basket')
      }, 2500)
      return
    }
    localStorage.removeItem('basket')
    if (Response.data.Result.url) {
      location.replace(Response.data.Result.url)
    } else {
      router.push('/apps/my_orders')
    }
  })
}