import { get_config } from '@/models/changeType'
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)
const routes: Array<RouteConfig> = [
  // {
  //   path: '/messages',
  //   name: 'messages',
  //   component: () => import ('../views/Ticket/messageInbox.vue')
  // },
  {
    path: '/login',
    name: 'login',
    component: () => import ( '../views/auth/login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import ( '../views/auth/register.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import ( '../views/UserProfile/user-profile.vue')
  },
  // {
  //   path: '/ticket',
  //   name: 'ticket',
  //   component: () => import ( '../views/Ticket/sendTicket.vue')
  // },
  // {
  //   path: '/support',
  //   name: 'support',
  //   component: () => import ( '../views/Ticket/ticketInbox.vue')
  // },
  // {
  //   path: '/chat_support',
  //   name: 'chat_support',
  //   component: () => import ( '../views/Ticket/supportInbox.vue')
  // },
  // {
  //   path: '/test',
  //   name: 'test',
  //   component: () => import ( '../views/test.vue')
  // },
  {
    path: '/orders/:factoryID',
    name: 'orders',
    component: () => import ( '../views/Factor/orders.vue'),
    props: true
  },
  {
    path: '/order_detail/:factoryID',
    name: 'order_detail',
    component: () => import ( '../views/Factor/orderDetail.vue'),
    props: true
  },
  {
    path: '/basket_detail/:carogID/:factoryID',
    name: 'basket_detail',
    component: () => import ( '../views/Factor/basketProductDetail.vue'),
    props: true
  },
  {
    path: '/location/:locationID',
    name: 'location',
    component: () => import ( '../views/Address/location.vue'),
    props: true
  },
  {
    path: '/add_address',
    name: 'add_address',
    component: () => import ( '../views/Address/addAddress.vue'),
    beforeEnter(to,from,next) {
      if (from.name == "my_location") {
        const from_location = localStorage.getItem("from_location")
        if (from_location) {
          next("/apps")
          localStorage.removeItem("from_location")
        } else {
          next()
        }
      } else {
        next()
      }
    }
  },
  {
    path: '/transaction/:purseID',
    name: 'transaction',
    component: () => import ( '../views/Wallet/transaction.vue'),
    props: true
  },
  {
    path: '/change_password',
    name: 'change_password',
    component: () => import ( '../views/UserProfile/changePassword.vue')
  },
  {
    path: '/',
    name: 'home',
    component: () => import ( '../views/shop/home/home.vue')
  },
  {
    path: '/basket',
    name: 'basket',
    component: () => import ( '../views/shop/Basket/basket.vue'),
  },
  {
    path: '/reciever',
    name: 'reciever',
    component: () => import ( '../views/shop/Basket/basketRecieverDetail.vue')
  },
  {
    path: '/add_reciever',
    name: 'addReciever',
    component: () => import ( '../views/shop/Basket/addAddressBasket.vue'),
    beforeEnter(to,from,next) {
      if (from.name == "send_time") {
        next("/reciever")
      } else {
        next()
      }
    }
  },
  {
    path: '/product_detail/:productsID',
    name: 'product_detail',
    component: () => import ( '../views/shop/productDetail/productDetail.vue'),
    props: true
  },
  {
    path: '/send_time',
    name: 'send_time',
    component: () => import ( '../views/shop/Basket/sendTime.vue')
  },
  // {
  //   path: '/multiple_product',
  //   name: 'multiple_product',
  //   component: () => import ( '../views/shop/Basket/multipleBasketProduct.vue')
  // },
  {
    path: '/payment',
    name: 'payment',
    component: () => import ( '../views/shop/Basket/payment.vue'),
  },
  {
    path: '/search',
    name: 'search',
    component: () => import ( '../views/shop/search/searchResult.vue')
  },
  {
    path: '/apps/:app?',
    name: 'apps',
    component: () => import ( '../views/shop/home/megaApp.vue'),
    props: true
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import ( '../views/shop/home/orders.vue'),
    props: true
  },
  {
    path: '/add_comment/:productID',
    name: 'add_comment',
    component: () => import ( '../views/shop/productDetail/addComment.vue'),
    props: true
  },
  {
    path: '/forget_password',
    name: 'forget_password',
    component: () => import ( '../views/auth/forgetPassword.vue')
  },
  {
    path: '/confirmation',
    name: 'confirmation',
    component: () => import ( '../views/auth/confirmation.vue')
  },
  // {
  //   path: '/sellers',
  //   name: 'sellers',
  //   component: () => import ( '../views/shop/productDetail/sellers.vue')
  // },
  {
    path: `/categories`,
    name: 'categories',
    component: () => import ( '../views/shop/categories/category.vue'),
  },
  {
    path: `/subordinate`,
    name: 'subordinate',
    component: () => import ( '../views/shop/categories/subordinate.vue'),
  },
  // {
  //   path: `/bookmark`,
  //   name: 'bookmark',
  //   component: () => import ( '../views/UserProfile/favs.vue'),
  // },
]
const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  
  const logo =  localStorage.getItem('logo')
  if (logo) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('dynamic-favicon');
  link.id = 'dynamic-favicon';
  link.rel = 'shortcut icon';
  link.href = logo
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
  let config = {} as any
  if ( to.name == 'subordinate' || to.name == 'rules' || to.name == 'test' || to.name == 'categories' || to.name == 'search' || to.name == 'confirmation' || to.name == 'home' || to.name == 'forget_password' || to.name == 'product_detail' || to.name == 'basket' || to.name == 'login' || to.name == 'register') {
    config = localStorage.getItem('config')
    if (config) {
          config = JSON.parse(config)
          Vue.nextTick(() => {
            document.title = config.title
          })
          next()
    } else {
      get_config().then(() => {
        config = localStorage.getItem
        if (config) {
          config = JSON.parse(config)
        }
      })
      Vue.nextTick(() => {
        document.title = config.title
      })
      next()
    }
  } else {
    const token = localStorage.getItem('access_token')
    if (token) {
      config = localStorage.getItem('config')
    if (config) {
          config = JSON.parse(config)
          Vue.nextTick(() => {
            document.title = config.title
          })
          next()
    } else {
      get_config().then(() => {
        config = localStorage.getItem
        if (config) {
          config = JSON.parse(config)
        }
      })
      Vue.nextTick(() => {
        document.title = config.title
      })
      next()
    }
    } else {
      config = localStorage.getItem('config')
    if (config) {
          config = JSON.parse(config)
          Vue.nextTick(() => {
            document.title = config.title
          })
          next('/login')
    } else {
      get_config().then(() => {
        config = localStorage.getItem
        if (config) {
          config = JSON.parse(config)
        }
      })
      Vue.nextTick(() => {
        document.title = config.title
      })
      next('/login')
    }
    }
  }
})
export default router
