import { changeTypePrice, calOffer, create_url_image, changeTypePrice_format, changeTypePrice_format_gross } from "@/models/changeType"
import { viewbasket_products } from '../../Basket/models/Basket'
type block = {
    id: string;
    title: string;
    banner: string;
  }
  type blockList = {
    lists: [];
    blockID: string;
  }
  type blockProduct = {
    products: [];
    blockID: string;
    parentID: string;
  }
  type product_basket = {
    offer: string;
    count: string;
    price: number;
    priceFormate: string;
    price_gross: number;
    priceGrossFormate: string;
    onhand: string;
    min_order: string;
    max_order: string;
    max_request: string;
    lot_size: string;
    id: string;
    error: string;
    image: string;
    lead_time: string;
    org_id: string;
    org_title: string;
    title: string;
    unit_title: string;
    Is_Bulk: string;
  }

  type select_list = {
    index: number;
    block_id: number;
  }

const state = {
    blocks: [],
    blockList: [],
    blockProduct: [] as blockProduct[],
    banner: [],
    brands: [],
    selected_list: [] as select_list[],

}

const mutations = {
    banner: (state,paylod) => {
        state.banner = paylod
      },
      brands: (state,paylod) => {
        state.brands = paylod
      },
      block: (state,paylod) => {
        state.blocks = paylod
      },
      blockList: (state,paylod) => {
        state.blockList = paylod
      },
      blockProduct: (state,paylod) => {
        const product = state.blockProduct.find(p => p.parentID == paylod.parentID)
        if (!product) {
          state.blockProduct.push(paylod)
        } else {
          product.products = paylod.products
        }
      },
      selected_list: (state, paylod) => {
        const index = state.selected_list.find(i => i.block_id == paylod.block_id)
        if (index) {
          index.index = paylod.index
        }
        state.selected_list.push(paylod)
      }
}

const actions = {
  brands: ({commit}, paylod) => {
    const banners = [] as any
    for (const banner of paylod) {
      const _banner = {
        id: banner.id,
        image: create_url_image(banner.image_resize, 65, 65),
        tag_id: banner.tag_id
      }
      banners.push(_banner)
    }
      commit('brands',banners)
  },
      selected_list: ({commit}, paylod) => {
        commit('selected_list', paylod)
      },
      banner: ({commit}, paylod) => {
        const banners = [] as any
      for (const banner of paylod) {
        const _banner = {
          id: banner.id,
          image_resize: create_url_image(banner.image_resize, 400, 400),
          tag_id: banner.tag_id
        }
        banners.push(_banner)
      }
        commit('banner',banners)
      },
      blocks: ({commit}, blocks) => {
        let mainBlock = [] as block[]
        for (const block of blocks) {
          if (block.style != 'offer') {
            const Block = { id: block.id, title: block.title, banner: block.banner }
            mainBlock.push(Block) 
          }
        }
        commit('block', mainBlock)
      },
      blocksList: ({commit}, blocksList) => {
        commit("blockList", blocksList)
      },
       blockProduct: ({commit}, productsBlock) => {
        let products_bluck = [] as product_basket[]
        for (const product of productsBlock.products) {
          let offer = ''
          if (product.orgs[0].Prod_Gross_Price != product.orgs[0].Prod_Price && product.orgs[0].Prod_Gross_Price != 0) {
            offer = calOffer({
            price: product.orgs[0].Prod_Price,
            gross_price: product.orgs[0].Prod_Gross_Price,
            typeToChange: 'percent',
            TypeToJoin: '%'
          })
          }
          const pro = {
            count: '0',
            offer: offer,
            price: changeTypePrice({
              price: product.orgs[0].Prod_Price,
              Tax_price: product.orgs[0].Tax_Price
            }),
            priceFormate: changeTypePrice_format({
              price: product.orgs[0].Prod_Price,
              Tax_price: product.orgs[0].Tax_Price,
            }),
            price_format_pack: changeTypePrice_format({
              price: product.Packing_Weight * product.orgs[0].Prod_Price,
              Tax_price: product.orgs[0].Tax_Price,
            }),
            price_gross: changeTypePrice({
              price: product.orgs[0].Prod_Gross_Price,
              Tax_price: product.orgs[0].Tax_Price
            }),
            priceGrossFormate: changeTypePrice_format_gross({
              price: product.orgs[0].Prod_Gross_Price,
              Tax_price: product.orgs[0].Tax_Price,
            }),
            onhand: product.onhand,
            min_order: product.orgs[0].Min_Order,
            max_order: product.orgs[0].Max_Order,
            max_request: product.onhand,
            lot_size: product.orgs[0].Lot_Size,
            id: product.id,
            error: product.error,
            image: create_url_image(product.image, 200, 200),
            lead_time: product.orgs[0].Lead_Time,
            org_id: product.orgs[0].Org_ID_FK,
            org_title: product.orgs[0].Org_Title,
            title: product.title,
            unit_title: product.unit_title,
            Is_Bulk: product.Is_Bulk,
            Packing_Weight: product.Packing_Weight
          }
          products_bluck.push(pro)
          commit('blockProduct', {products: products_bluck, parentID: productsBlock.parentID})
        }
        setTimeout(() => {
          const basket = localStorage.getItem('basket')
         if (basket) {
           commit('basket', JSON.parse(basket))
         } else {
          viewbasket_products()
         }
        }, 800);
      },
}

export default { state, mutations, actions }